import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"

import Masthead from "../components/strapi/structure/Masthead"
import Twocol from "../components/strapi/structure/TwoCol"
import Craftsmanship from "../components/strapi/structure/Craftsmanship"
import Outropiggy from "../components/strapi/structure/Outropiggy"

import Predelivery from "../components/strapi/pdf/predelivery-guide"

export default function PartsService({ data }) {
  return (
    <Layout>
      <SEO title="Parts & Service" />

      {data.allStrapiPartsServices.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.ps_mh.mh_title}
            mhsubtitle={node.ps_mh.mh_subtitle}
            mhbackground={node.ps_mh.mh_bg.publicURL}
          />
          <Twocol
            title={node.ps_parts.txtimg_title}
            description={node.ps_parts.txtimg_description}
            blueTitle={node.ps_parts.txtimg_bluetitle}
            blueLink={node.ps_parts.txtimg_bluelink}
            bgColor={node.ps_parts.txtimg_bgcolor}
            order={node.ps_parts.txtimg_order}
            rowimg={node.ps_parts.txtimg_img.publicURL}
          />
          <Twocol
            title={node.ps_services.txtimg_title}
            description={node.ps_services.txtimg_description}
            blueTitle={node.ps_services.txtimg_bluetitle}
            blueLink={"/hot-tubs"}
            bgColor={node.ps_services.txtimg_bgcolor}
            order={node.ps_services.txtimg_order}
            rowimg={node.ps_services.txtimg_img.publicURL}
          />
          <Twocol
            title={node.ps_delivery.txtimg_title}
            description={node.ps_delivery.txtimg_description}
            blueTitle={node.ps_delivery.txtimg_bluetitle}
            pdf={<Predelivery />}
            bgColor={node.ps_delivery.txtimg_bgcolor}
            order={node.ps_delivery.txtimg_order}
            rowimg={node.ps_delivery.txtimg_img.publicURL}
          />
          <Craftsmanship
            title={node.ps_craftsmanship.craft_title}
            description={node.ps_craftsmanship.craft_description}
          />
          <Outropiggy outro={node.ps_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query PartsService {
    allStrapiPartsServices {
      edges {
        node {
          id # id of the node
          ps_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          ps_parts {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_img {
              publicURL
            }
          }
          ps_services {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_img {
              publicURL
            }
          }
          ps_delivery {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_img {
              publicURL
            }
          }
          ps_craftsmanship {
            id
            craft_title
            craft_description
          }
          ps_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`
