// For use, select the 'Craftsmanship' element in Strapi

import React from "react"
import { Container, Grid } from "@material-ui/core"

export default function Craftsmanship(props) {
  return (
    <div className="flex items-center craftsmanship" key={props.key}>
      <Container>
        <Grid item>
          <Grid item md={12} className="md:text-center white-text">
            <h2>{props.title}</h2>
            <h3>{props.description}</h3>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
