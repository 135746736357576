import React from 'react'
import { Button } from '@material-ui/core'
import predelivery from '../../../images/pdf/pre-delivery-guide-2020-update.pdf'

export default function PreDelivery() {
    return (
        <>
            <a href={predelivery} target="_blank">
                <Button variant="contained" color="primary"><span className="text-white">Download Pre-Delivery Guide</span></Button>
            </a>
        </>
    )
}
